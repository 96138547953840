






































































































































































import { defineComponent } from "@vue/composition-api";
import { isBefore } from "date-fns";
import { DateTime } from "klokwerk";
import { mapActions as mapPiniaActions, mapState as mapPiniaState, mapStores } from "pinia";
import { mapActions } from "vuex";
import { coachApi, reserveerApi } from "@/lib/backend";
import type { IomodelsCoachResult, IomodelsCoachappLidMetPrioInfo } from "@/lib/backend/club.api";
import type { Afspraak, AfsprakenMaand } from "@/lib/backend/coach.api";
import type { IomodelsVestiging } from "@/lib/backend/reserveer.api";
import { APPOINTMENT_MAP } from "@/lib/constants/options";
import { logger } from "@/logger";
import { useAuthStore } from "@/pinia/auth";
import { useCoachStore } from "@/pinia/coach";
import { useGebruikerStore } from "@/pinia/gebruiker";
import { useLocationStore } from "@/pinia/location";
import { usePopupStore } from "@/pinia/popup";
import { CoachService } from "@/services/coach";
import {
	getDateFromDatum,
	getDateFromDatumAndTijd,
	getDayMonthYear,
	getMonthYear,
	getTimeOfDay,
} from "@/utils/date";
import { removeDuplicates } from "@/utils/removeDuplicates";

type Toggle = {
	slug: string;
	text: string;
	enabled: boolean;
};

type Data = {
	loading: boolean;
	vestigingSlug?: IomodelsVestiging["slug"];
	coach?: string;
	slotMessage?: string;
	toggleMessage?: string;
	toggles: Toggle[];
	disabledDates: {
		to: Date;
	};
	comments?: string;
	date: Date;
	appointments: AfsprakenMaand;
	selectedSlot?: Afspraak;
};

export default defineComponent({
	name: "PfgGebruikerAfspraakInplannen",
	data(): Data {
		return {
			loading: false,
			vestigingSlug: undefined,
			coach: undefined,
			slotMessage: undefined,
			toggleMessage: undefined,
			toggles: Object.entries(APPOINTMENT_MAP).map(([slug, appointment]) => ({
				slug,
				...appointment,
				enabled: false,
			})),
			disabledDates: {
				to: new DateTime().setDate((current) => current.date - 1).native,
			},
			comments: undefined,
			date: new Date(),
			appointments: {},
			selectedSlot: undefined,
		};
	},
	computed: {
		...mapStores(usePopupStore, useGebruikerStore, useAuthStore),
		...mapPiniaState(useCoachStore, {
			coachesByLocation: "coachesByLocationSlugSortedByName",
		}),
		...mapPiniaState(useLocationStore, {
			location: "location",
			locationSlug: "locationSlug",
			locations: "locationsSortedByName",
		}),
		...mapPiniaState(useGebruikerStore, {
			id: "id",
			gebruiker: "basicInfo",
		}),
		showKickOff(): boolean {
			return !(this.gebruikerStore.lidInfo?.onboardingAfgerond ?? true);
		},
		locationName(): string {
			return this.locations.find((location) => location.slug === this.vestigingSlug)?.naam ?? "";
		},
		slots(): Afspraak[] {
			if (!this.appointments) return [];

			const slots = this.appointments[getDayMonthYear(this.date, "short")] ?? [];
			return removeDuplicates(slots, "slot");
		},
		hasActiveSlots(): boolean {
			if (!this.slots) return false;

			return this.slots.some((slot) => !slot.busy && slot.status !== "afgelopen");
		},
		selectableCoaches(): IomodelsCoachResult[] {
			if (!this.vestigingSlug) {
				return [];
			}

			return this.coachesByLocation(this.vestigingSlug);
		},
	},
	watch: {
		date() {
			this.selectedSlot = undefined;
		},

		location: {
			handler(newVal) {
				logger.log("location changed", newVal);
				this.vestigingSlug = newVal?.slug;
			},
			immediate: true,
		},

		vestigingSlug: {
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					this.onChangeVestiging();
				}
			},
		},

		toggles: {
			handler(newVal: Toggle[]) {
				if (newVal.some((toggle: Toggle): boolean => toggle.enabled)) this.toggleMessage = "";
			},
			deep: true,
		},
		$route: "check",

		gebruiker: {
			handler(newVal, oldVal) {
				if (newVal?.id !== oldVal?.id) {
					logger.log("gebruiker changed", newVal, oldVal);
					const verstigingId = this.gebruiker?.vestiging;
					const vestiging = this.locations.find((location) => location.id === verstigingId);

					if (vestiging) {
						this.vestigingSlug = vestiging.slug;
					}
				}
			},
		},
	},

	async created() {
		await this.initLocation();
		await this.initCoach();
		await this.check();
	},
	methods: {
		...mapPiniaActions(useCoachStore, {
			initCoach: "init",
		}),
		...mapPiniaActions(useLocationStore, {
			initLocation: "init",
		}),
		...mapPiniaActions(useGebruikerStore, ["set", "getBasicInfo"]),
		...mapPiniaActions(useGebruikerStore, ["set", "getLidInfo"]),
		...mapActions("modal", ["openModal"]),
		shouldShowToggle(toggle: Toggle): boolean {
			if (toggle.slug !== "kickOff") return true;

			return this.showKickOff;
		},
		async check() {
			this.loading = true;

			const { id } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			const currentId = this.id;

			if (currentId !== numberId) this.set(numberId);

			await this.getRequiredInfo();

			this.checkAndSetCoach();
			this.loading = false;
		},
		async getRequiredInfo() {
			await this.getBasicInfo();
			await this.getLidInfo();
			await this.getAfspraken();
		},
		checkAndSetCoach() {
			const myCoachId = this.authStore.userClub?.coachId;

			if (this.selectableCoaches.some((coach) => coach.id === myCoachId)) {
				this.coach = String(myCoachId);
			}
		},
		reset() {
			// @ts-expect-error Apply not correctly typed
			Object.assign(this.$data, this.$options.data?.apply(this));
		},
		async changedMonth(date: Date | { timestamp: string }) {
			// The datepicker module either emits a date when a new month is selected or an object with a timestamp.
			this.date = date instanceof Date ? date : new Date(date.timestamp);

			await this.getAfspraken();
		},
		selectSlot(slot: Afspraak) {
			if (slot.busy) return;

			this.selectedSlot = slot;
			this.slotMessage = undefined;
		},
		async onChangeVestiging() {
			await this.getAfspraken();
			this.coach = undefined;
			this.selectedSlot = undefined;
		},
		async getAfspraken() {
			try {
				const response = await coachApi.api.appointmentsMonthDetail(
					this.vestigingSlug ?? "all",
					getMonthYear(this.date),
				);

				switch (response.status) {
					case 200: {
						this.appointments = response.data;
						break;
					}

					default: {
						throw response;
					}
				}
			} catch (error) {
				logger.error(error);
			}
		},
		savePopup() {
			if (!this.selectedSlot?.afspraakId) this.slotMessage = "Selecteer een afspraak tijdstip";
			if (this.toggles.every((toggle) => !toggle.enabled))
				this.toggleMessage = "Selecteer minstens één optie";

			if (this.slotMessage || this.toggleMessage) return false;

			if (!this.selectedSlot) return false;

			const gebruiker = this.gebruikerStore.basicInfo;

			if (!gebruiker) return false;

			const body = `Wil je de volgende afspraak maken:
                <p>
                    <b>${this.selectedSlot.datum}</b> om <b>${this.selectedSlot.slot}</b> in
                    <b>${
											this.locations.find(
												(location: IomodelsVestiging): boolean =>
													location.slug === this.vestigingSlug,
											)?.naam
										}</b>
                    voor
                    <b>${gebruiker.voornaam} ${gebruiker.achternaam}</b>
                </p>

                Tijdens de afspraak worden de volgende punten behandeld:
                <ul>
                    ${this.toggles
											.filter((toggle) => toggle.enabled)
											.map((toggle) => `<li><b>${toggle.text}</b></li>`)
											.join("")}
                </ul>
                Met de volgende opmerking:
                <p>
                    <b>${this.comments ? this.comments : "Geen opmerkingen."}</b>
                </p>
            `;

			return this.popupStore.open({
				title: "Let op!",
				body,
				buttons: {
					cancel: "Annuleren",
					confirm: "Afspraak bevestingen",
				},
				callback: async () => {
					await this.save(gebruiker);
				},
			});
		},
		async save(gebruiker: IomodelsCoachappLidMetPrioInfo) {
			/**
			 * KickOff is not defined in the SwaggerDocs. If it is not send with the request, we get a 400
			 * error that it is missing. When kickOff is false it works. When kickOff is true we get a 500
			 * internal server error.
			 */
			try {
				const response = await new CoachService().makeAppointment(
					Number(this.selectedSlot?.afspraakId),
					{
						lidId: gebruiker.id,
						lidNaam: `${gebruiker.voornaam} ${gebruiker.achternaam}`,
						status: "aangemeld",
						...this.toggles.reduce<Record<string, boolean>>(
							(obj, toggle) => ({
								...obj,
								[toggle.slug]: toggle.enabled,
							}),
							{},
						),
						opmerkingen: this.comments,
					},
				);

				switch (response.status) {
					case 200: {
						if (this.coach)
							await reserveerApi.fitnessafspraken.wijzigAfspraak({
								...response.data,
								trainerId: Number(this.coach),
							});

						const date = new Date(response.data.datum);

						this.popupStore.open({
							title: "Gelukt",
							body: `De afspraak voor ${gebruiker.voornaam} is opgeslagen: <b>${getDayMonthYear(
								date,
							)}</b> om <b>${getTimeOfDay(date)}.`,
							buttons: {
								confirm: "Sluiten",
							},
							callback: () =>
								this.$router.push({
									name: "Gebruiker",
									params: { id: String(gebruiker.id) },
								}),
						});

						this.reset();

						break;
					}
					case 403: {
						throw new Error("De afspraak is geannuleerd");
					}

					case 404: {
						throw new Error("Afspraak is niet bekend");
					}

					case 400:
					default: {
						throw response;
					}
				}
			} catch (error) {
				const message = error;

				this.popupStore.showError(
					`Er ging iets mis bij het opslaan van de afspraak: ${message}.<br/>Probeer het later nog eens`,
				);
			}
		},
		getDateFromDatum,
		getDateFromDatumAndTijd,
		isBefore,
	},
});
