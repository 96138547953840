import { logger } from "@/logger";

export function removeDuplicates<T, K extends keyof T>(array: T[], key: K): T[] {
	const uniqueKeys = new Set<T[K]>();

	const result = array.filter((value) => {
		if (uniqueKeys.has(value[key])) {
			return false;
		}
		uniqueKeys.add(value[key]);
		return true;
	});

	const diff = array.length - result.length;
	logger.log(`Removed ${diff} duplicates.`);

	return result;
}
